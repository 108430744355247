/* eslint-disable */
import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import { Global } from "@emotion/core"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import qs from "qs"

import Header from "../../components/header"
import Layout from "../../layouts"
import PageMetadata from "../../components/page-metadata"
import scrollToAnchor from "../../utils/scroll-to-anchor"
import FeaturedSites from "./featured-sites"
import FilteredShowcase from "./filtered-showcase"

class ShowcasePage extends Component {
  showcase = React.createRef()
  state = {
    filters: [],
  }

  componentDidMount() {
    const {
      location: { search = `` },
    } = this.props

    const { filters } = qs.parse(search.replace(`?`, ``))

    if (filters && filters.length) {
      this.setFilters(filters)
    }
  }

  componentDidUpdate() {
    const {
      location: { pathname, search },
    } = this.props
    const queryString = qs.stringify(this.state)

    if (search.replace(/^\?/, ``) !== queryString) {
      navigate(`${pathname}?${queryString}`, { replace: true })
    }
  }

  setFilters = filters => {
    this.setState({
      filters: [].concat(filters),
    })

    scrollToAnchor(this.showcase.current, () => {})()
  }

  render() {
    const { data } = this.props
    const { filters } = this.state

    return (
      <Layout>
        <Global
          styles={{
            body: {
              overflow: `visible`,
            },
          }}
        />
        <Header applyTopPadding={false} isFullWidth={true} />
        <main>
          <SkipNavTarget />
          <PageMetadata
            title="Showcase"
            description="Gallery of sites using Gatsby across the web, find inspiration or inspect the code of popular projects."
          />
          <FeaturedSites
            setFilters={this.setFilters}
            featured={data.featured.nodes}
          />
          <div id="showcase" css={{ height: 0 }} ref={this.showcase} />
          <FilteredShowcase
            filters={filters}
            setFilters={this.setFilters}
            data={data}
          />
        </main>
      </Layout>
    )
  }
}

export default ShowcasePage

export const pageQuery = graphql`
  query ShowcasePageQuery {
    featured: allWpShowcaseSite(
      filter: {
        showcaseSiteFields: { screenshot: { id: { ne: null } } }
        categories: { nodes: { elemMatch: { name: { in: "Featured" } } } }
      }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        slug
        categories {
          nodes {
            name
          }
        }
        showcaseSiteFields {
          title
          sourceUrl
          screenshot {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          mainUrl
          description
          builtByUrl
          builtBy
        }
      }
    }
    allWpShowcaseSite(
      filter: { showcaseSiteFields: { screenshot: { id: { ne: null } } } }
    ) {
      nodes {
        slug
        categories {
          nodes {
            name
          }
        }
        showcaseSiteFields {
          title
          sourceUrl
          screenshot {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          mainUrl
          description
          builtByUrl
          builtBy
        }
      }
    }
  }
`
